<template>
  <div class="d-flex justify-content-center">
    <div
      :class="participant.bookingOwner ? 'green-confirmed' : 'red-confirmed'"
      class="confirmed-btn pointer"
      @click.stop="updateOwner"
    >
      <label class="text-center mb-0 confirmed-btn-text pointer">
        {{ label }}
      </label>
    </div>
  </div>
</template>
<script>

import {_put} from "@api/doinsport/services/httpService";

export default {
  props: {
    participant: {
      type: Object,
      default: () => {
      }
    }
  },
  computed: {
    label() {
      return this.participant.bookingOwner
        ?
        this.$t('general.actions.yes')
        :
        this.$t('general.actions.no')
        ;
    },
  },
  methods: {
    updateOwner() {
      if (this.$route.name === 'bookings' || this.$route.name === 'planning') {
        _put(this.participant.iriParticipant, {bookingOwner: !this.participant.bookingOwner})
          .then((response) => {
            this.participant.bookingOwner = response.data.bookingOwner;
          })
        ;
      } else {
        this.participant.bookingOwner = !this.participant.bookingOwner;
      }
    },
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/planning/_confirmed-participant-btn";
</style>
